<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-row>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productmeasure.prod_me_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productmeasure.prod_me_abrev" :rules="rules.abrevRules" label="Abreviação" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-switch color="lime" v-model="productmeasure.prod_me_status" label="Status"></v-switch>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-col>

            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormProductMeasuresComponent",
    props: {
        productmeasure: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_me_id: '',
                    prod_me_nome: '',
                    prod_me_slug: '',
                    prod_me_icone: '',
                    prod_me_banner: '',
                    prod_me_link: '',
                    prod_me_order: '',
                    prod_me_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 100) || 'Nome precisa ter até 100 Caracteres'
                ]
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateProductMeasure' : 'storeProductMeasure';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.productmeasure)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'product-measures'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
